import { css } from "emotion"
import firebase from "firebase"
import React, { useContext, useRef } from "react"
import { Link, Route, Switch } from "react-router-dom"
import { Assessment, Button } from "../../components"
import { UNSTUCK_GREEN } from "../../constants"
import { assessmentData, calcNetPromoter } from "./../../assessment-data"
import TeamPDFLink from "./../../components/TeamPDFLink"
import { AppContext } from "./../../store"

/*
import DashboardSidebar from "./DashboardSidebar";
import DashboardBlank from "./DashboardBlank";
import DashboardResults from "./DashboardResults";
import DashboardAssessment from "./DashboardAssessment";
*/

// /dashboard               Just logged in, waiting for church data or assessment to load
// /dashboard/new           Take a new assessment (redirects here if brand new account)
// /dashboard/:assessment   Continue taking assessment or view assessment results

const InviteCode = ({ uid }) => {
  const inviteCodeInputRef = useRef(null)
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 25,
      }}>
      <input
        ref={inviteCodeInputRef}
        type="text"
        style={{
          padding: 10,
          background: "#eee",
          border: "none",
          outline: "none",
          margin: 0,
          fontSize: 17,
          width: 800,
        }}
        value={"http://teams.theunstuckchurch.com/invite?code=" + uid}
      />
      <div
        style={{ background: "#ddd", padding: 10, cursor: "pointer" }}
        onClick={() => {
          var copyText = inviteCodeInputRef.current
          /* Select the text field */
          copyText.select()
          copyText.setSelectionRange(0, 99999) /*For mobile devices*/

          /* Copy the text inside the text field */
          document.execCommand("copy")
        }}>
        <span>Copy</span>
      </div>
    </div>
  )
}

const Dashboard = () => {
  const { user, church, loadingChurch, submitLeaderAssessment, logout } =
    useContext(AppContext)

  if (loadingChurch) {
    return null
  }

  if (!church || !church.assessments) {
    return (
      <div>
        <Assessment
          onSubmit={(assessmentData) =>
            submitLeaderAssessment({
              ...assessmentData,
              dateCreated: Date.now(),
            })
          }
        />
      </div>
    )
  }

  var netPromoterAverage = calcNetPromoter(church.assessments)

  function handleReset() {
    if (!church.assessments) {
      return alert("Assessment already reset")
    }

    if (
      !window.confirm(
        `Are you sure you want to reset your team’s assessment? All your team’s previous answers will be discarded.`
      )
    ) {
      return
    }

    firebase
      .database()
      .ref()
      .child("teamChurch")
      .child(user.uid)
      .update({
        timeCreated: Date.now(),
        expirationDate: null,
        assessments: null,
        archivedAssessments: {
          ...(church.archivedAssessments || {}),
          [Date.now()]: church.assessments,
        },
      })

    window.location.reload()
  }

  return (
    <div>
      <Switch>
        <Route
          path="/dashboard"
          render={() => (
            <div style={{ width: "100vw", height: "100vh", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F3F2F1",
                  width: 300,
                  height: "100%",
                  padding: "25px 35px",
                }}>
                <Link to="/">
                  <img
                    src={require("../../assets/images/logo.png")}
                    alt="The Unstuck Group"
                    role="logo"
                    className={css`
                      height: 20px;
                    `}
                  />
                </Link>
                <br />
                <br />
                <br />
                <p style={{ fontSize: 18 }}>
                  Welcome, <br />
                  <b>{church.churchName}</b> <br />
                  <span
                    style={{ fontSize: 12, fontStyle: "italic", opacity: 0.7 }}>
                    {church.teamName}
                  </span>
                </p>
                <br />
                <br />
                <br />
                {assessmentData.sections.map((section, sectionIndex) => (
                  <div key={sectionIndex} style={{ marginBottom: 10 }}>
                    <a
                      style={{ textDecoration: "none", fontSize: 18 }}
                      href={"#section-" + sectionIndex}>
                      {section.name}
                    </a>
                  </div>
                ))}
                <div style={{ height: 100 }} />
                <br />
                <button className="dot" onClick={logout}>
                  Logout
                </button>
              </div>
              <div style={{ height: "100vh", flex: 1, overflowY: "scroll" }}>
                <div style={{ padding: 50, marginBottom: 30 }}>
                  <h1 style={{ fontSize: 32, marginTop: 0 }}>
                    Unstuck Team Assessment Results
                  </h1>
                  <hr />
                  <div style={{ marginBottom: 25 }}>
                    <h4 style={{ fontWeight: 300, marginBottom: 10 }}>
                      Invite your team to take the assessment. Share this link:
                    </h4>
                    <InviteCode uid={user.uid} />
                  </div>
                  <p>
                    Number of submissions:{" "}
                    {Object.keys(church.assessments).length}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0 40px 0",
                    }}>
                    <TeamPDFLink
                      timeSubmitted={church.timeCreated}
                      isLeaderOnly={false}
                      logo={require("../../assets/images/logo.png")}
                      churchId={user.uid}
                      church={church}>
                      <div
                        style={{
                          display: "inline-block",
                          background: "#333",
                          borderRadius: 4,
                          padding: "1em 1.5em",
                          marginRight: "1em",
                          cursor: "pointer",
                          transition: "opacity 0.2s",
                        }}
                        className={css`
                          &:hover {
                            opacity: 0.8;
                          }
                        `}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 471.2 471.2"
                            style={{
                              width: 22,
                              marginRight: 10,
                              enableBackground: "new 0 0 471.2 471.2",
                            }}
                            fill="#fff">
                            <g>
                              <path d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8    c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8    C471.2,236.25,465.2,230.15,457.7,230.15z" />
                              <path d="M226.1,346.75c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-62.7,62.8    V30.75c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1    L226.1,346.75z" />
                            </g>
                          </svg>
                          <span style={{ color: "#fff" }}>
                            Download Results PDF
                          </span>
                        </div>
                      </div>
                    </TeamPDFLink>
                    <Button kind="outline" onClick={handleReset}>
                      Reset Assessment
                    </Button>
                  </div>
                  {assessmentData.sections.map((section, sectionIndex) => {
                    var answerTotal = 0
                    var answerTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                    assessmentData.sections[sectionIndex].questions.forEach(
                      (question, questionIndex) => {
                        Object.keys(church.assessments).forEach(
                          (assessmentKey) => {
                            var assessment = church.assessments[assessmentKey]
                            var assessmentScore =
                              assessment[sectionIndex + ":" + questionIndex]
                            answerTotal += assessmentScore
                            answerTotals[questionIndex] += assessmentScore
                          }
                        )
                      }
                    )

                    var answerAverages = []

                    var answerAverages = answerTotals.map(
                      (x) => x / Object.keys(church.assessments).length
                    )

                    var answerAverage =
                      answerTotal / 12 / Object.keys(church.assessments).length

                    return (
                      <div
                        key={sectionIndex}
                        style={{
                          padding: 20,
                          border: "1px solid #ddd",
                          borderRadius: 5,
                          marginBottom: 40,
                        }}
                        id={"section-" + sectionIndex}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 20,
                          }}>
                          <div
                            style={{
                              width: 70,
                              height: 70,
                              position: "relative",
                            }}>
                            <h4
                              style={{
                                textAlign: "center",
                                paddingTop: 23,
                                marginTop: 0,
                              }}>
                              {answerAverage.toFixed(1)}
                            </h4>
                            <svg
                              style={{
                                margin: 0,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: 70,
                                height: 70,
                              }}
                              viewBox="0 0 36 36">
                              <path
                                style={{
                                  stroke: UNSTUCK_GREEN,
                                  fill: "none",
                                  strokeWidth: 2.8,
                                  strokeLinecap: "round",
                                }}
                                d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                stroke="#444"
                                strokeWidth="1"
                                strokeDasharray={`${answerAverage * 10}, 100`}
                              />
                            </svg>
                          </div>
                          <h4
                            style={{
                              marginLeft: 20,
                              marginTop: 0,
                              marginBottom: 0,
                            }}>
                            Section {sectionIndex + 1}: {section.name}
                          </h4>
                        </div>
                        {section.questions.map((question, questionIndex) => {
                          return (
                            <div
                              key={questionIndex}
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #eee",
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginRight: 20,
                                }}>
                                <p style={{ marginRight: 10 }}>
                                  {questionIndex + 1}.
                                </p>
                                <p>{question.text}</p>
                              </div>
                              <p>{answerAverages[questionIndex].toFixed(1)}</p>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                  <div>
                    <h4>Net Promoter Score</h4>
                    <h1 style={{ marginTop: 0 }}>{netPromoterAverage}</h1>
                    <p>
                      *The Net Promoter Score is the world’s leading metric for
                      measuring customer satisfaction and loyalty. It goes
                      beyond measuring how satisfied a customer is with a
                      company; the Net Promoter Score system is designed to
                      gauge their willingness to recommend it to others.
                    </p>
                    <a
                      target="_blank"
                      href="https://blog.hubspot.com/service/what-is-a-good-net-promoter-score">
                      What is a good net promoter score?
                    </a>
                    <img
                      style={{ width: "90%", marginTop: 40 }}
                      src="/net-promoter-score.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
        <Route
          path="/dashboard/invite"
          render={({ match }) => {
            return <h1>Invite</h1>
          }}
        />
      </Switch>
    </div>
  )
}

export default Dashboard
