import React, { useContext } from "react";
import { css } from "emotion";
import {
  Button,
  Card,
  Container,
  Graph,
  Nav,
  Text,
  Section,
  Scroll,
} from "../../components";
import { BACKGROUND, SECONDARY_TEXT } from "../../constants";
import { AppContext } from "./../../store";
import { assessmentData } from "./../../assessment-data";

const CardItem = ({ title, description, index }) => {
  return (
    <div
      className={css`
        min-height: 200px;
        background-color: transparent;
        width: 200px;
        border: 1px solid #f1f1f1;
        perspective: 1000px;
        padding: 10px;
        border: none;

        &:hover {
          > div {
            transform: rotateY(180deg);
          }
        }
      `}
    >
      <div
        className={css`
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          box-sizing: border-box;
        `}
      >
        <div
          className={css`
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            text-align: center;
            border: 1px solid #eee;
            padding: 10px;
            border-radius: 4px;
          `}
        >
          <img
            src={`/images/sample-score-${index + 1}.png`}
            style={{ width: 130 }}
          />
          <p
            style={{
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {title}
          </p>
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transform: rotateY(180deg);
            border: 1px solid #eee;
            padding: 10px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <p
            style={{
              fontSize: 14,
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const { authenticating } = useContext(AppContext);

  if (authenticating) {
    return false;
  }

  return (
    <div>
      <Nav />
      <header>
        <Section
          style={{ paddingTop: 25, paddingBottom: 40 }}
          className={css`
            overflow: hidden;
          `}
        >
          <Container
            className={css`
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              @media (max-width: 800px) {
                flex-direction: column;
              }
            `}
          >
            <div style={{ minWidth: "40%", maxWidth: "100%" }}>
              <Text.H1>Unstuck Teams Assessment</Text.H1>
              <br />
              <Text.Large leading="copy" measure>
                Building a thriving and high-performing team is no small task.
                The first step? Get a better understanding of your current
                reality.
              </Text.Large>
              <br />
              <Text.Small leading="copy" measure>
                It can be easy in ministry to get so busy doing church that we
                rarely stop to evaluate how we are doing <i>team</i>. This
                online tool helps you engage your team in an honest assessment
                of six critical areas for a high-impact team:
              </Text.Small>
              <br />
              <Text.Small leading="copy" measure>
                <i>
                  1. personal health <br />
                  2. team health <br />
                  3. personal performance <br />
                  4. team performance <br />
                  5. systems <br />
                  6. culture <br />
                </i>
              </Text.Small>
              <br />
              <Button kind="secondary" to="/signin">
                Get Started
              </Button>
            </div>
            <div>
              <img
                src={require("../../assets/images/ui.png")}
                className={css`
                  flex-shrink: 0;
                  margin: -10px 25px -10px -200px;
                  width: 110%;
                  height: auto;
                  @media (max-width: 800px) {
                    width: 100%;
                    margin: 50px 0 -50px;
                  }
                `}
              />
            </div>
          </Container>
        </Section>
      </header>

      <Section>
        <div style={{ paddingRight: 10, paddingLeft: 10 }}>
          <Text.H1 style={{ marginTop: 0, textAlign: "center" }}>
            Results Across 6 Metrics
          </Text.H1>
          <br />
          <Text.Large
            leading="copy"
            style={{ textAlign: "center", maxWidth: 600, margin: "0 auto" }}
          >
            Easily gain perspective on your team's personal health, team health,
            personal performance, team performance, systems, and culture.
          </Text.Large>
          <div
            style={{
              width: 600,
              maxWidth: "90%",
              display: "block",
              margin: "40px auto 20px auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {assessmentData.sections.map((section, i) => (
                <CardItem
                  key={i}
                  index={i}
                  title={section.name}
                  description={section.shortDescription}
                />
              ))}
            </div>
          </div>
        </div>
      </Section>

      <Section style={{ paddingTop: 10, paddingBottom: 10 }}>
        <div
          style={{
            background: "#F3F2F1",
            padding: 30,
            maxWidth: 1080,
            margin: "0 auto",
          }}
        >
          <div
            className={css`
              display: flex;
              align-items: center;

              @media (max-width: 868px) {
                flex-direction: column;
              }
            `}
          >
            <div
              style={{
                width: 64,
                height: 64,
                borderRadius: 64,
                background:
                  'url("/images/testimonial-profile.jpeg") center center no-repeat',
                backgroundSize: "cover",
              }}
            />
            <div style={{ marginLeft: 20 }}>
              <h4 style={{ fontWeight: 700, fontSize: 28, margin: 0 }}>
                Bobby Kirchner
              </h4>
              <p style={{ margin: 0 }}>
                Executive Pastor at Big Valley Grace Church in Modesto, CA
              </p>
            </div>
          </div>
          <div
            className={css`
              display: flex;

              @media (max-width: 868px) {
                flex-direction: column;
              }
            `}
          >
            <p style={{ flex: 1, fontSize: 22, lineHeight: 1.6 }}>
              "The Unstuck Teams Assessment has been an invaluable tool for our
              staff team. It provided a mirror for us to look at and identify
              truth about current and strengths and weaknesses of our team, as
              identified by our team. The assessment also provided a tool for
              productive discussion with teammates about how we together can
              improve as a staff team."
            </p>
            <div style={{ width: 429, height: 250, flexShrink: 0 }}>
              <img
                style={{
                  marginTop: 50,
                  width: 429,
                  transform: "translate(90px,-50px)",
                }}
                src="/images/home-testimonial.png"
              />
            </div>
          </div>
        </div>
      </Section>
      <div style={{ height: 200 }} />
      <Section
        className={css`
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center 30%;
          background-image: url(${require("../../assets/images/worship.png")});
        `}
      >
        <Container
          className={css`
            text-align: center;
            color: white;
          `}
        >
          <Text.H1>Unstuck Teams</Text.H1>
          <br />
          <br />
          <Button kind="secondary" to="/signin">
            Take the Assessment
          </Button>
        </Container>
      </Section>

      <Section>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 30,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <h1>Healthy + High Performing</h1>
          <Text.Large leading="copy" measure>
            Your team is the most valuable asset you have in making progress
            toward your vision. Investing in your people is one of the most
            strategic commitments you can make.
            <br />
            <br />
            The Unstuck Group helps churches build healthy and high-performing
            teams, equipped to close the gap between vision and execution. We
            can guide you to lead staff teams that love working together and get
            stuff done—<b>spiritually, emotionally</b>, and{" "}
            <b>relationally healthy</b>, as well as <b>productive</b> and{" "}
            <b>high-performing</b>.
            <br />
            <br />
            Interested in learning about the Unstuck Teams Process?{" "}
            <a target="_blank" href="https://theunstuckgroup.com/start/">
              Let’s talk
            </a>
            .
          </Text.Large>
        </div>
        <img style={{ width: "100%" }} src="/images/home-collage.png" />
      </Section>

      <footer>
        <Section>
          <Container>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                @media (max-width: 800px) {
                  align-items: flex-start;
                  flex-direction: column;
                  img {
                    margin-bottom: 50px;
                  }
                }
              `}
            >
              <img
                src={require("../../assets/images/logo.png")}
                alt="The Unstuck Group"
                role="logo"
                className={css`
                  height: 20px;
                `}
                style={{}}
              />
              <Text measure>
                The Unstuck Teams Assessment is just one tool{" "}
                <b>The Unstuck Group</b> uses to help churches assess ministry
                health and get a fresh perspective.
                <br />
                <br />
                Learn more about the{" "}
                <a href="https://theunstuckgroup.com/teams" target="_blank">
                  Unstuck Teams consulting process
                </a>
                .
              </Text>
            </div>
          </Container>
        </Section>
      </footer>
    </div>
  );
};

Home.displayName = "Home";

export default Home;
