import moment from "moment"

const assessmentData = {
  sections: [
    {
      name: "Personal Health",
      description:
        "How are you doing spiritually, emotionally, relationally and physically?",
      shortDescription:
        "Spiritually, emotionally, relationally, physically: How are we doing as individuals?",
      teamNote: "",
      questions: [
        {
          text: "My personal connection with God feels vibrant and growing.",
        },
        {
          text: "I have developed genuine friendships with others on the staff.",
        },
        {
          text: "I work a reasonable number of hours and am able to have adequate, uninterrupted time for my family/personal life.",
        },
        {
          text: "My team leader/supervisor demonstrates care for me personally.",
        },
        {
          text: "I regularly practice Sabbath (a 24 hour period to stop, unplug, and rest).",
        },
        {
          text: "My team leader/supervisor and team respect and champion my family time and practice of Sabbath.",
        },
        {
          text: "I am doing ministry from a place of gratitude and joy.",
        },
        {
          text: "I take good care of my body by eating healthy and exercising.",
        },
        {
          text: "I am NOT consistently stressed out about my ministry job.",
        },
        {
          text: "I make my personal growth as a disciple of Jesus a high priority.",
        },
        {
          text: "My family/significant relationships are happy that I work at our church.",
        },
        {
          text: "I personally demonstrate care for my fellow staff members.",
        },
      ],
    },
    {
      name: "Team Health",
      description:
        "How is our team doing spiritually, emotionally, relationally and physically?",
      shortDescription:
        "Spiritually, emotionally, relationally, physically: How are we doing as a team?",
      teamNote:
        "When answering questions in this section, please have the primary team you work with in mind.",
      questions: [
        {
          text: "We have genuine times of prayer and being in God’s Word together.",
        },
        {
          text: "Generally speaking, people demonstrate the ability and willingness to resolve conflict within our team.",
        },
        {
          text: "Our leaders model integrity and honesty.",
        },
        {
          text: "Giving your honest opinion feels safe.",
        },
        {
          text: "We have a strong sense of family (or community) on our team.",
        },
        {
          text: "There is good cooperation across departments.",
        },
        {
          text: "People on our team admit their mistakes.",
        },
        {
          text: "People on the team encourage and affirm one another.",
        },
        {
          text: "Our team has fun together.",
        },
        {
          text: "We celebrate our wins.",
        },
        {
          text: "People are “present” in conversations and meetings, rather than being plugged into their electronic devices.",
        },
        {
          text: "The people on our team show vulnerability and openness.",
        },
      ],
    },
    {
      name: "Personal Performance",
      description: "Do you receive appropriate coaching and accountability?",
      shortDescription:
        "Are we setting people up to win? Are we holding them accountable?",
      teamNote: "",
      questions: [
        {
          text: "I know exactly what the “win” is for my role.",
        },
        {
          text: "In the last 60 days, my team leader/supervisor has talked to me about my performance.",
        },
        {
          text: "My role aligns with my gifting and passion.",
        },
        {
          text: "My team leader/supervisor and I have talked about my priorities for the next few weeks/months.",
        },
        {
          text: "I receive affirmation when I am doing a good job.",
        },
        {
          text: "I have an intentional plan for my personal development.",
        },
        {
          text: "I feel empowered to carry out my role.",
        },
        {
          text: "I don't let technology (e-mail, text messages, social media, internet browsing) distract me from focusing on my priorities.",
        },
        {
          text: "I am constantly learning, growing and striving to get better at my job.",
        },
        {
          text: "I spend the majority of my time focused on the priorities and tasks most critical for my role.",
        },
        {
          text: "I have the adequate resources and equipping to effectively perform my role.",
        },
        {
          text: "I feel like I am able to speak into decisions that impact me.",
        },
      ],
    },
    {
      name: "Team Performance",
      description:
        "Are we effectively working together to advance towards a shared vision?",
      shortDescription:
        "Are we effectively working together to advance towards a shared vision?",
      teamNote:
        "When answering questions in this section, please have the primary team you work with in mind.",
      questions: [
        {
          text: "The objectives and priorities for our team are clear.",
        },
        {
          text: "Our team is effective in accomplishing its priorities in a timely manner.",
        },
        {
          text: "There is clarity about who has authority to make decisions.",
        },
        {
          text: "We have a collaborative mindset within our team.",
        },
        {
          text: "We have alignment and are all pulling in the same direction.",
        },
        {
          text: "People on our team have a strong work ethic.",
        },
        {
          text: "Our meetings are generally productive.",
        },
        {
          text: "We commit to an idea and see it through before we jump onto the next new idea.",
        },
        {
          text: "We are held accountable for our goals and performance.",
        },
        {
          text: "We use honest feedback, evaluation, and debriefing as a means to improve.",
        },
        {
          text: "Our leaders are clear about where we are going as a church and we talk about it often.",
        },
        {
          text: "Our team does a satisfactory job of planning ahead.",
        },
      ],
    },
    {
      name: "Organizational Systems",
      description: "Are our systems serving us well?",
      shortDescription: "Are our systems serving us well?",
      teamNote:
        "When answering questions in this section, please have the church-wide organization in mind.",
      questions: [
        {
          text: "Our church is intentional in onboarding new people and orienting them to our systems.",
        },
        {
          text: "I believe I am fairly compensated.",
        },
        {
          text: "Our benefits are satisfactory.",
        },
        {
          text: "Our reporting structure and org chart are clear.",
        },
        {
          text: "We have effective internal communication.",
        },
        {
          text: "We have a helpful and clear performance evaluation process.",
        },
        {
          text: "Our church is well run (good management practices).",
        },
        {
          text: "We utilize an effective project/task management system to keep people focused on priorities. ",
        },
        {
          text: "We have effective and clear financial processes.",
        },
        {
          text: "Generally, I am able to take my vacation time when I desire.",
        },
        {
          text: "We have had training on risk issues (like abuse or someone who is suicidal).",
        },
        {
          text: "Our all-staff meetings are meaningful and useful.",
        },
      ],
    },
    {
      name: "Organizational Culture",
      description: "Do you believe this is a great place to work?",
      shortDescription: "Is this a great place to work? ",
      teamNote:
        "When answering questions in this section, please have the church-wide organization in mind.",
      questions: [
        {
          text: "Expected staff behaviors/values have been clearly defined and articulated.",
        },
        {
          text: "This is a fun and life-giving place to work.",
        },
        {
          text: "Our staff culture feels healthy.",
        },
        {
          text: "Our team takes time to celebrate our wins.",
        },
        {
          text: "Our leaders demonstrate humility.",
        },
        {
          text: "We have an appropriate focus on both relationships and results.",
        },
        {
          text: "Leadership seeks and takes into account the input of staff and volunteers.",
        },
        {
          text: "The organizational culture values a healthy rhythm/pace of life.",
        },
        {
          text: "There is appropriate transparency and openness by the leadership.",
        },
        {
          text: "Those on our staff are treated with utmost dignity and respect.",
        },
        {
          text: "Our church does a good job of evaluating, debriefing, and making adjustments.",
        },
        {
          text: "We have a culture of trust within our church staff.",
        },
      ],
    },
  ],
}

const calcNetPromoter = (assessments) => {
  var numPromoters = 0 //9,10
  var numPassives = 0 //7-8
  var numDetractors = 0 //0-6
  Object.keys(assessments).forEach((assessmentKey) => {
    var promoterScore = assessments[assessmentKey]["5:12"]

    if (promoterScore <= 6) {
      numDetractors += 1
    } else if (promoterScore <= 8) {
      numPassives += 1
    } else {
      numPromoters += 1
    }
  })

  var totalPromoters = Object.keys(assessments).length

  var promoterPercentage = (numPromoters / totalPromoters) * 100
  var passivesPercentage = (numPassives / totalPromoters) * 100
  var detractorsPercentage = (numDetractors / totalPromoters) * 100

  let netPromoterAverage = promoterPercentage - detractorsPercentage
  netPromoterAverage = netPromoterAverage.toFixed(0)

  return netPromoterAverage
}

export { calcNetPromoter, assessmentData }
