import React, { Component } from "react";
import { css } from "emotion";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { Button, Error, Overlay, Text, TextInput } from "../../components";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  signInWithSSO = async () => {
    try {
      const provider = new firebase.auth.SAMLAuthProvider("saml.unstuckwp");
      const result = await firebase.auth().signInWithPopup(provider);
      console.log({ result });
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
  };

  signInWithEmail = async (e) => {
    e.preventDefault();
    try {
      this.setState({ submitting: true });
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password);
      this.setState({ submitting: false });
    } catch (error) {
      this.setState({ error });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Overlay>
        <form
          onSubmit={this.signInWithEmail}
          className={css`
            width: 100%;
            max-width: 400px;
            margin: 0 10px;
            display: flex;
            align-items: stretch;
            flex-direction: column;
          `}
        >
          <Text.H2
            className={css`
              text-align: center;
              margin-bottom: 20px;
            `}
          >
            Sign in to Unstuck Teams
          </Text.H2>
          <Button kind="secondary" type="button" onClick={this.signInWithSSO}>
            Sign in with Unstuck
          </Button>
          <div
            className={css`
              position: relative;
              margin: 2rem 0;
              hr {
                margin: 0;
              }
              h5 {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                background-color: white;
                padding: 0 5px;
              }
            `}
          >
            <hr />
            <Text.H5>OR</Text.H5>
          </div>
          <TextInput
            type="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextInput
            type="password"
            name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
            autoFocus={false}
          />
          <Text>
            <Link to="/forgot">Forgot your password?</Link>
          </Text>
          <Button
            type="submit"
            kind="primary"
            disabled={this.state.submitting}
            className={css`
              margin: 20px 0;
            `}
          >
            Sign in with email
          </Button>
          <Error error={this.state.error} />
          <Text
            className={css`
              margin-top: 30px;
            `}
          >
            Don't have an account yet?{" "}
            <a href="https://theunstuckgroup.com/my-account/">
              <b>Sign Up</b>
            </a>
            !
          </Text>
        </form>
      </Overlay>
    );
  }
}

export default SignIn;
