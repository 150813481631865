import React, { Component } from "react";
import { css } from "emotion";
import firebase from "firebase";
import { Redirect, Link } from "react-router-dom";

import { Button, Overlay, Text, TextInput } from "../../components";

class Forgot extends Component {
  state = {
    email: "",
    sent: false,
    error: null
  };

  handleSubmit = async e => {
    e.preventDefault();
    try {
      await firebase.auth().sendPasswordResetEmail(this.state.email);
      this.setState({ sent: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    if (this.state.sent) {
      return (
        <Overlay>
          <div
            className={css`
              width: 100%;
              max-width: 500px;
              display: flex;
              flex-direction: column;
              text-align: center;
              & > span {
                margin: 20px 0;
              }
            `}
          >
            <h3>Password Reset Email Sent</h3>
            <p leading="copy">
              An email has been sent to your email address,{" "}
              <b>{this.state.email}</b>. Follow the directions in the email to
              reset your password.
            </p>
            <p>
              Go back to <Link to="/signin">Sign In</Link>.
            </p>
          </div>
        </Overlay>
      );
    }

    return (
      <Overlay>
        <form
          onSubmit={this.handleSubmit}
          className={css`
            margin: 50px auto;
            width: 100%;
            max-width: 400px;
            display: flex;
            align-items: stretch;
            flex-direction: column;
          `}
        >
          <Text.H3
            className={css`
              margin-bottom: 20px;
            `}
          >
            Enter your email to reset your password.
          </Text.H3>
          <TextInput
            name="email"
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <Button
            type="submit"
            kind="primary"
            className={css`
              margin-bottom: 20px;
            `}
          >
            Reset Password
          </Button>
          <Text>
            Go back to <Link to="/signin">Sign In</Link>.
          </Text>
          {this.state.error && <Text>{this.state.error}</Text>}
        </form>
      </Overlay>
    );
  }
}

export default Forgot;
