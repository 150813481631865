import React, { Component } from "react";
import { css } from "emotion";
import firebase from "firebase";
import { Link } from "react-router-dom";
import {
  Button,
  Error as ErrorComponent,
  Overlay,
  Text,
  TextInput,
} from "../../components";

class SignUp extends Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.email) return;
    try {
      console.log(btoa(this.state.email));
      this.setState({ submitting: true });
      const inviteRef = firebase
        .database()
        .ref()
        .child("teamInvite")
        .child(btoa(this.state.email));
      const inviteSnapshot = await inviteRef.once("value");
      const invite = inviteSnapshot.val();

      if (invite) {
        // Create user account
        const userData = await firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.state.email,
            this.state.password
          );
        console.log("USER", userData.user.uid);
        // Create church
        await firebase
          .database()
          .ref()
          .child("teamsChurch")
          .child(userData.user.uid)
          .set({
            name: invite.churchName,
            email: invite.email,
            location: invite.location || "",
            customCTA: invite.customCTA || "",
            timeCreated: firebase.database.ServerValue.TIMESTAMP,
          });
        // Update invite
        await inviteRef.update({ pending: false });
      } else {
        throw new Error(
          "This email address does not yet have access to Unstuck Teams. Please contact help@theunstuckgroup.com for details about getting started."
        );
      }
    } catch (error) {
      console.log("ERROR", error);
      this.setState({ error });
    } finally {
      this.setState({ submitting: false });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Overlay>
        <form
          onSubmit={this.handleSubmit}
          className={css`
            margin: 50px auto;
            width: 100%;
            max-width: 400px;
            display: flex;
            align-items: stretch;
            flex-direction: column;
          `}
        >
          <Text.H2
            className={css`
              text-align: center;
              margin-bottom: 20px;
            `}
          >
            Create your <br />
            Unstuck Teams account.
          </Text.H2>
          <TextInput
            name="email"
            type="email"
            placeholder="Enter your email"
            onChange={this.handleChange}
            value={this.state.email}
          />
          <TextInput
            name="password"
            type="password"
            placeholder="Enter a password"
            onChange={this.handleChange}
            value={this.state.password}
          />
          <Button
            type="submit"
            kind="primary"
            className={css`
              margin: 20px 0;
            `}
          >
            Sign Up
          </Button>
          <ErrorComponent error={this.state.error} />
          <Text
            className={css`
              margin-top: 30px;
            `}
          >
            Already have an account?{" "}
            <Link to="/signin">
              <b>Log In</b>
            </Link>
            .
          </Text>
        </form>
      </Overlay>
    );
  }
}

export default SignUp;
