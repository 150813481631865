import React from "react"
import { css, cx } from "emotion"

const Overlay = ({ className, ...rest }) => {
  return (
    <div
      {...rest}
      className={cx(
        css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 10%;
        `,
        className,
      )}
    />
  )
}

export default Overlay
