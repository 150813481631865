import React from "react"
import { css, cx } from "emotion"
import { BACKGROUND } from "../constants"

const TextInput = ({ className, ...rest }) => {
  return (
    <input
      {...rest}
      className={cx(
        css`
          display: block;
          appearance: none;
          font-size: 1em;
          border-radius: 4px;
          margin: 10px 0;
          padding: 1em 1.5em;
          border: 1px solid ${BACKGROUND};
        `,
        className,
      )}
    />
  )
}

export default TextInput
