import { injectGlobal } from "emotion"
import { PRIMARY_TEXT, UNSTUCK_BLUE } from "./constants"

injectGlobal`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    overflow-x: hidden;
    margin: 0;
    color: ${PRIMARY_TEXT};
    font-family: Lato, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25em;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1.rem;
  }

  a {
    color: ${PRIMARY_TEXT};
  }

  button,
  input,
  select {
    outline-color: ${UNSTUCK_BLUE};
  }

  input,
  button {
    -webkit-appearance: none;
  }
`
