import React from "react"
import { css, cx } from "emotion"

const Scroll = ({ vertical, horizontal, className, ...rest }) => {
  return (
    <div
      {...rest}
      className={cx(
        css`
          overflow-y: ${vertical ? "auto" : "hidden"};
          overflow-x: ${horizontal ? "auto" : "hidden"};
          backface-visibility: hidden;
          -webkit-overflow-scrolling: touch;
        `,
        className
      )}
    />
  )
}

Scroll.defaultProps = {
  vertical: true,
}

export default Scroll
