import React from "react"
import { css, cx } from "emotion"

const Container = props => {
  return (
    <div
      {...props}
      className={cx(
        css`
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 50px;
          @media (max-width: 800px) {
            padding: 0 25px;
          }
          @media (max-width: 400px) {
            padding: 0 10px;
          }
        `,
        props.className,
      )}
    />
  )
}

export default Container
