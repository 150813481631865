import moment from "moment"
import React, { useState, useEffect, useRef } from "react"

import api from "./api"

import firebase from "firebase"
import "firebase/functions"
import { firebaseConfig } from "./config"
import { isContext } from "vm"
export const AppContext = React.createContext()

firebase.initializeApp(firebaseConfig)

if (typeof window !== "undefined") {
  window.firebase = firebase
}

function Store({ children }) {
  // state
  const [user, setUser] = useState(null)
  const [authenticating, setAuthenticating] = useState(true)
  const [church, setChurch] = useState(null)
  const [myAssessment, setMyAssessment] = useState(null)
  const [loadingChurch, setLoadingChurch] = useState(false)
  const [loadingMyAssessment, setLoadingMyAssessment] = useState(false)
  const [inviteCode, setInviteCode] = useState(null)

  const logout = () => {
    firebase.auth().signOut()
  }

  const submitLeaderAssessment = async (assessmentData) => {
    const inviteRef = firebase
      .database()
      .ref()
      .child("teamInvite")
      .child(btoa(user.email))
    const inviteSnapshot = await inviteRef.once("value")
    const invite = inviteSnapshot.val()

    var newChurch = {
      ...invite,
      timeCreated: Date.now(),
      assessments: {
        [user.uid]: assessmentData,
      },
    }

    if (church && church.archivedAssessments) {
      newChurch.archivedAssessments = church.archivedAssessments
    }

    setChurch(newChurch)

    await firebase
      .database()
      .ref()
      .child("teamChurch")
      .child(user.uid)
      .set(newChurch)
  }

  const submitAssessment = async (assessmentData, churchId) => {
    setMyAssessment(assessmentData)

    await firebase
      .database()
      .ref()
      .child("teamChurch")
      .child(inviteCode)
      .child("assessments")
      .push(assessmentData)
  }

  const loadInviteChurch = async (uid) => {
    setLoadingChurch(true)
    const churchRef = firebase.database().ref().child("teamChurch").child(uid)

    var timeCreatedSnap = await churchRef.child("timeCreated").once("value")
    var timeCreated = timeCreatedSnap.val()

    setChurch({ id: uid, timeCreated })
    setLoadingChurch(false)
  }

  const loadChurch = async (uid) => {
    setLoadingChurch(true)
    const churchRef = firebase.database().ref().child("teamChurch").child(uid)
    const churchSnapshot = await churchRef.once("value")
    const newChurch = churchSnapshot.val()

    if (newChurch) {
      setChurch(newChurch)
    }

    setLoadingChurch(false)
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user)
      setAuthenticating(false)

      if (user) {
        loadChurch(user.uid)
      }
    })

    function getUrlVars() {
      var vars = {}
      var parts = window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
          vars[key] = value
        }
      )
      return vars
    }
    let invite = getUrlVars().code
    if (invite) {
      setInviteCode(invite)
      loadInviteChurch(invite)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        user,
        church,
        loadingChurch,
        myAssessment,
        inviteCode,

        authenticating,
        submitAssessment,
        submitLeaderAssessment,
        logout,
      }}>
      {children}
    </AppContext.Provider>
  )
}

export default Store
