import React, { useContext, useState } from "react"
import get from "lodash/get"
import { css, cx } from "emotion"
import firebase from "firebase"
import { Button, Container, Scroll } from "./index"
import { Redirect, Route, Switch } from "react-router-dom"
import { AppContext } from "./../store"
import { UNSTUCK_BLUE, UNSTUCK_GREEN } from "../constants"

import { assessmentData } from "./../assessment-data"

const Assessment = ({ onSubmit }) => {
  const [currentSection, setCurrentSection] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(-2)
  const [isNetPromoterScore, setIsNetPromoterScore] = useState(false)

  console.log({ currentQuestion })

  const [responses, setResponses] = useState({})

  console.log(currentSection + " : " + currentQuestion)

  const setResponse = (selection) => {
    const newResponses = {
      ...responses,
      [currentSection + ":" + currentQuestion]: parseInt(selection),
    }
    setResponses(newResponses)
    next(newResponses)
  }

  const next = (newResponses) => {
    if (currentQuestion < 11) {
      setCurrentQuestion(currentQuestion + 1)
    } else if (currentSection < 5) {
      setCurrentQuestion(-1)
      setCurrentSection(currentSection + 1)
    } else if (!isNetPromoterScore) {
      setCurrentQuestion(12)
      setIsNetPromoterScore(true)
    } else {
      onSubmit(newResponses)
    }
  }
  const prev = () => {
    if (isNetPromoterScore) {
      setCurrentSection(5)
      setCurrentQuestion(11)
      setIsNetPromoterScore(false)
    } else if (currentQuestion > -1) {
      setCurrentQuestion(currentQuestion - 1)
    } else if (currentSection > 0) {
      setCurrentQuestion(11)
      setCurrentSection(currentSection - 1)
    } else {
      setCurrentSection(0)
      setCurrentQuestion(-1)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key >= 0 && e.key <= 9) {
      if (e.key === 0) {
        setResponse(10)
      } else {
        setResponse(e.key)
      }
    }
  }

  const SelectionItem = ({ item }) => {
    const [hover, setHover] = useState(false)
    return (
      <div
        key={item}
        className={css`
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        `}>
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            width: 40,
            height: 40,
            border: `2px solid ${UNSTUCK_GREEN}`,
            borderRadius: 40,
            marginRight: 0,
            cursor: "pointer",
            background: hover ? UNSTUCK_GREEN : "transparent",
          }}
          key={item}
          onClick={() => setResponse(item)}>
          <h1
            style={{
              margin: 0,
              fontSize: 18,
              textAlign: "center",
              marginTop: 7,
              color: hover ? "#fff" : "#333",
            }}>
            {item}
          </h1>
        </div>
        {item != 10 && (
          <div style={{ height: 2, width: 40, background: UNSTUCK_GREEN }} />
        )}
      </div>
    )
  }
  const ScoreSelection = ({ includeZero }) => {
    const numArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    if (includeZero) {
      numArray.unshift(0)
    }
    return (
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        <div style={{ paddingLeft: 35, paddingRight: 35 }}>
          <div
            className={css`
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            `}>
            {numArray.map((item) => {
              return <SelectionItem item={item} />
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: includeZero ? 867 : 780,
              maxWidth: "100%",
              transform: "translateX(-10px)",
            }}>
            {includeZero ? (
              <p style={{ textAlign: "center" }}>
                Not at all
                <br />
                likely.
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                Strongly
                <br />
                disagree.
              </p>
            )}
            {includeZero ? (
              <p style={{ textAlign: "center" }}>
                Extremely
                <br />
                likely.
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                Strongly
                <br />
                agree.
              </p>
            )}
          </div>
          <br />
          <br />
        </div>
        {(currentQuestion != -1 || currentSection != 0) && (
          <Button onClick={prev} kind="outline" style={{ fontSize: 14 }}>
            ‹ Previous Question
          </Button>
        )}
      </div>
    )
  }

  const totalQuestions = 72
  const currentQuestionIndex =
    currentSection * 12 + Math.max(currentQuestion, 0)
  const percentageComplete = currentQuestionIndex / totalQuestions
  let progressPixels = 0

  console.log({ percentageComplete })

  if (window.innerWidth <= 1080) {
    progressPixels =
      (window.innerWidth - window.innerWidth / 6) * percentageComplete +
      window.innerWidth / 12
  } else {
    progressPixels =
      (window.innerWidth - window.innerWidth / 6) * percentageComplete +
      (window.innerWidth - 1080) / 2 + //the part of the page outside the container
      window.innerWidth / 12 //the first 1/12th of the container to get to the first item.
  }

  return (
    <div
      css={{
        height: "100vh",
        width: "100vw",
        ":focus": {
          outline: "none !important",
        },
      }}
      tabIndex="0"
      onKeyPress={handleKeyPress}>
      {isNetPromoterScore ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: `url(/images/section-${currentSection}.png) center center no-repeat`,
            backgroundSize: "cover",
            position: "relative",
          }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "#fff",
              opacity: 0.8,
            }}
          />
          <img
            style={{ height: 20, position: "absolute", top: 20, left: 20 }}
            src={require("./../assets/images/logo.png")}
          />
          <div
            style={{ maxWidth: 1080, margin: "0 auto", position: "relative" }}>
            <h1 style={{ marginTop: 0, paddingTop: 200, textAlign: "center" }}>
              How likely is it that you would recommend a friend or colleague to
              join the staff team at your church?
            </h1>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ScoreSelection includeZero={true} />
            </div>
          </div>
        </div>
      ) : currentQuestion == -2 ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: `url(/images/section-${currentSection}.png) center center no-repeat`,
            backgroundSize: "cover",
            position: "relative",
          }}>
          <img
            style={{ height: 20, position: "absolute", top: 20, left: 20 }}
            src={require("./../assets/images/logo.png")}
          />
          <div
            style={{
              background: "#fff",
              padding: 40,
              borderRadius: 4,
              textAlign: "center",
              width: "80%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            <h1
              className={css`
                @media (max-width: 900px) {
                  font-size: 20px;
                }
              `}
              style={{ textAlign: "center", margin: 0, color: "#333" }}>
              Welcome to the Unstuck Teams Assessment.
            </h1>
            <p style={{ marginBottom: 40 }}>
              <i>
                This assessment is 73 questions and should take less than 10
                minutes.
              </i>
              <br />
              <br />
            </p>
            <Button kind="secondary" onClick={next}>
              Let's Get Started!
            </Button>
          </div>
        </div>
      ) : currentQuestion == -1 ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: `url(/images/section-${currentSection}.png) center center no-repeat`,
            backgroundSize: "cover",
            position: "relative",
          }}>
          <img
            style={{ height: 20, position: "absolute", top: 20, left: 20 }}
            src={require("./../assets/images/logo.png")}
          />
          <div
            style={{
              background: "#fff",
              padding: 40,
              borderRadius: 4,
              textAlign: "center",
              width: "80%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            <h1
              className={css`
                @media (max-width: 900px) {
                  font-size: 20px;
                }
              `}
              style={{ textAlign: "center", margin: 0, color: "#333" }}>
              Section {currentSection + 1}:{" "}
              {assessmentData.sections[currentSection].name}
            </h1>
            <p style={{ marginBottom: 40 }}>
              <i>Your responses are anonymous; be candid.</i>
              <br />
              <br />
              {assessmentData.sections[currentSection].description}
              <br />
              <br />

              {assessmentData.sections[currentSection].teamNote}
            </p>
            <Button kind="secondary" onClick={next}>
              Continue
            </Button>
            <div style={{ height: 30 }} />
            {(currentQuestion != -1 || currentSection != 0) && (
              <Button onClick={prev} kind="outline" style={{ fontSize: 14 }}>
                ‹ Previous Question
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: `url(/images/section-${currentSection}.png) center center no-repeat`,
            backgroundSize: "cover",
            position: "relative",
          }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "#fff",
              opacity: 0.8,
            }}
          />
          <img
            style={{ height: 20, position: "absolute", top: 20, left: 20 }}
            src={require("./../assets/images/logo.png")}
          />

          <div
            style={{ paddingTop: 60, width: "100vw", position: "relative" }}
            className={css`
              @media (max-width: 900px) {
                display: none;
              }
            `}>
            <div
              style={{
                maxWidth: 1080,
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-around",
              }}>
              {assessmentData.sections.map((section, sectionIndex) => {
                const isCurrentSection = sectionIndex === currentSection
                const isFutureSection = sectionIndex > currentSection
                const isPastSection = sectionIndex < currentSection
                return (
                  <div
                    key={sectionIndex}
                    style={{
                      zIndex: 2,
                      position: "relative",
                      width: "16.66%",
                    }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: isCurrentSection ? "700" : "300",
                        height: 40,
                      }}>
                      {section.name}
                    </p>
                    <div
                      style={{
                        margin: "0 auto",
                        width: 22,
                        height: 22,
                        border: `2px solid ${
                          isFutureSection ? "#ABABAB" : UNSTUCK_GREEN
                        }`,
                        backgroundColor: isPastSection ? UNSTUCK_GREEN : "#fff",
                        borderRadius: 24,
                      }}>
                      {isCurrentSection && (
                        <div
                          style={{
                            margin: 3,
                            borderRadius: 12,
                            width: 12,
                            height: 12,
                            background: UNSTUCK_GREEN,
                          }}></div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              style={{
                marginTop: -12,
                width: "100%",
                height: 2,
                background: "#A8A8A8",
                position: "relative",
                zIndex: 1,
              }}>
              <div
                style={{
                  width: progressPixels,
                  height: 4,
                  background: UNSTUCK_GREEN,
                  position: "absolute",
                  top: -1,
                  left: 0,
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "relative",
              paddingLeft: 20,
              paddingRight: 20,
              maxWidth: 1080,
              margin: "0 auto",
            }}>
            <h4 style={{ marginTop: 0, paddingTop: 80 }}>
              Section {currentSection + 1}:{" "}
              {assessmentData.sections[currentSection].name}
            </h4>
            <h1
              className={css`
                min-height: 4em;
                @media (max-width: 900px) {
                  font-size: 20px;
                }
              `}>
              {currentQuestion + 1}.{" "}
              {
                assessmentData.sections[currentSection].questions[
                  currentQuestion
                ].text
              }
            </h1>

            <ScoreSelection />
          </div>
        </div>
      )}
    </div>
  )
}

export default Assessment
