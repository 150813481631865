import React from "react"
import { css, cx } from "emotion"

const Section = props => {
  return (
    <div
      {...props}
      className={cx(
        css`
          width: 100%;
          padding: 100px 0;
        `,
        props.className,
      )}
    />
  )
}

export default Section
