import React from "react";
import { css } from "emotion";
import { Link } from "react-router-dom";
import Container from "./Container";
import Button from "./Button";
import { PRIMARY_TEXT } from "../constants";

const Nav = () => {
  return (
    <nav
      className={css`
        z-index: 9001;
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
      `}
    >
      <Container
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Link to="/">
          <img
            src={require("../assets/images/logo.png")}
            alt="The Unstuck Group"
            role="logo"
            className={css`
              height: 20px;
            `}
          />
        </Link>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            className={css`
              text-decoration: none;
              margin-right: 20px;
              color: PRIMARY_TEXT;
              @media (max-width: 500px) {
                display: none;
              }
            `}
            href="https://theunstuckgroup.com"
          >
            The Unstuck Group
          </a>
          <Button to="/signin" kind="outline">
            Sign In
          </Button>
        </div>
      </Container>
    </nav>
  );
};

export default Nav;
