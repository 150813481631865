import React, { useContext } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { firebase } from "./constants";
import Home from "./screens/home/Home";
import Dashboard from "./screens/dash/Dashboard";
import Forgot from "./screens/auth/Forgot";
import SignIn from "./screens/auth/SignIn";
import SignUp from "./screens/auth/SignUp";
import Invite from "./screens/home/Invite";

import { AppContext } from "./store";

const App = () => {
  const { user, authenticating } = useContext(AppContext);

  if (authenticating) {
    return <div style={{ padding: 100, textAlign: "center" }}>Loading...</div>;
  }

  return (
    <BrowserRouter>
      {user ? (
        <Switch>
          <Route
            path="/dashboard"
            render={(props) => <Dashboard {...props} user={user} />}
          />
          <Route path="/invite" exact component={Invite} />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      ) : (
        <div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/invite" exact component={Invite} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/signin" component={SignIn} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
