import React from "react"
import PropTypes from "prop-types"
import { css, cx } from "emotion"

const Text = props => {
  // omit props
  const {
    size,
    leading,
    measure,
    tracked,
    normal,
    bold,
    weight,
    italic,
    center,
    align,
    uppercase,
    lowercase,
    capitalize,
    underline,
    strike,
    pre,
    truncate,
    className,
    ...rest
  } = props

  let TextComponent = "span"
  if (size && size !== "large") TextComponent = size

  return (
    <TextComponent {...rest} className={cx(getClassName(props), className)} />
  )
}

Text.displayName = "Text"

Text.propTypes = {
  // font size
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "large", "small"]), // prettier-ignore
  // line height
  leading: PropTypes.string,
  // line length
  measure: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["wide", "narrow"])]), // prettier-ignore
  // letter spacing
  tracked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["tight", "mega"])]), // prettier-ignore
  // font weight
  normal: PropTypes.bool,
  bold: PropTypes.bool,
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700, 800, 900]),
  // font style
  italic: PropTypes.bool,
  // text align
  center: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"]),
  // text transform
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  // text decoration
  underline: PropTypes.bool,
  strike: PropTypes.bool,
  // white space
  pre: PropTypes.bool,
  // truncation
  truncate: PropTypes.bool,
}

const getClassName = p => {
  // default styles
  let styles = {
    margin: 0,
    display: "block",
  }
  // type scale
  if (p.size === "h1") styles.fontSize = "3rem"
  if (p.size === "h2") styles.fontSize = "2.25rem"
  if (p.size === "h3") styles.fontSize = "1.5rem"
  if (p.size === "h4" || p.size === "large") styles.fontSize = "1.25rem"
  if (p.size === "h5") styles.fontSize = "1rem"
  if (p.size === "h6" || p.size === "small") styles.fontSize = ".875rem"
  // line height
  styles.lineHeight = "1.15em"
  if (p.leading === "solid") styles.lineHeight = "1em"
  if (p.leading === "copy") styles.lineHeight = "1.5em"
  if (p.leading === "title") styles.lineHeight = "1.25em"
  // measure
  if (p.measure) styles.maxWidth = "30em"
  if (p.measure === "wide") styles.maxWidth = "40em"
  if (p.measure === "narrow") styles.maxWidth = "20em"
  // tracking
  if (p.tracked) styles.letterSpacing = ".1em"
  if (p.tracked === "tight") styles.letterSpacing = "-0.5em"
  if (p.tracked === "mega") styles.letterSpacing = ".25em"
  // font weight
  if (p.normal) styles.fontWeight = "normal"
  if (p.bold || p.b) styles.fontWeight = "bold"
  if (p.weight) styles.fontWeight = p.weight
  // font style
  if (p.italic || p.i) styles.fontStyle = "italic"
  // text align
  if (p.center) styles.textAlign = "center"
  if (p.align) styles.textAlign = p.align
  // text transform
  if (p.uppercase) styles.textTransform = "uppercase"
  if (p.lowercase) styles.textTransform = "lowercase"
  if (p.capitalize) styles.textTransform = "capitalize"
  // text decoration
  if (p.underline) styles.textDecoration = "underline"
  if (p.strike) styles.textDecoration = "strike"
  // whitespace
  if (p.pre) styles.whiteSpace = "pre"
  // truncation
  if (p.truncate) {
    styles.overflow = "hidden"
    styles.whiteSpace = "nowrap"
    styles.textOverflow = "ellipses"
  }
  return css(styles)
}

Text.H1 = props => <Text {...props} size="h1" />

Text.H2 = props => <Text {...props} size="h2" />

Text.H3 = props => <Text {...props} size="h3" />

Text.H4 = props => <Text {...props} size="h4" />

Text.H5 = props => <Text {...props} size="h5" />

Text.H6 = props => <Text {...props} size="h6" />

Text.Large = props => <Text {...props} size="large" />

Text.Small = props => <Text {...props} size="small" />

export default Text
