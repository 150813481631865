import firebase from "firebase";

export const firebaseConfig = {
  apiKey: "AIzaSyBlqTl1yMgFVnrmF9JA65HYp8rc_n2HLWA",
  authDomain: "vital-signs-db.firebaseapp.com",
  databaseURL: "https://vital-signs-db.firebaseio.com",
  projectId: "vital-signs-db",
  storageBucket: "vital-signs-db.appspot.com",
  messagingSenderId: "150979668551",
  appId: "1:150979668551:web:a61b414329f01767665f62"
};
