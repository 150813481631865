import React from "react";
import { css } from "emotion";
import Text from "./Text";

const Error = ({ error }) => {
  if (!error) {
    return null;
  }

  let message = error.message || error;
  if (typeof message !== "string") {
    message = "An unexpected error occurred.";
  }
  if (message == "The email address is already in use by another account.") {
    message =
      "You already have an account, maybe from the Vital Signs assessment. Click the link below to sign in.";
  }

  return (
    <Text
      className={css`
        color: red;
      `}
    >
      {message}
    </Text>
  );
};

export default Error;
