import React, { useContext } from "react"
import { Assessment } from "../../components"
import { AppContext } from "./../../store"

const Invite = () => {
  const { church, loadingChurch, myAssessment, submitAssessment, inviteCode } =
    useContext(AppContext)

  if (!inviteCode || loadingChurch) {
    return null
  }

  if (myAssessment) {
    return (
      <div style={{ paddingTop: 50, textAlign: "center" }}>
        <h1>Thank you for taking the assessment!</h1>
        <p>
          Your responses have been submitted successfully. You can close this
          window.
        </p>
      </div>
    )
  }

  return (
    <div>
      <Assessment
        onSubmit={(assessmentData) =>
          submitAssessment({ ...assessmentData, dateCreated: Date.now() })
        }
      />
    </div>
  )
}

export default Invite
