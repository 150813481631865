import React from "react";
import { css, cx } from "emotion";
import { Link } from "react-router-dom";
import {
  PRIMARY_TEXT,
  BACKGROUND,
  UNSTUCK_GREEN,
  UNSTUCK_BLUE,
  BORDER
} from "../constants";

const Button = React.forwardRef(({ className, kind, ...rest }, ref) => {
  const el = rest.to ? Link : rest.href ? "a" : "button";
  return React.createElement(el, {
    ref,
    ...rest,
    className: Button.getClassName({ kind, className })
  });
});

Button.getClassName = ({ kind, className }) =>
  cx(
    css`
      padding: 0;
      border: none;
      background: none;
      appearance: none;
      font-size: 1em;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      padding: 1em 1.5em;
      @media (max-width: 500px) {
        padding: 0.5em 1em;
      }
    `,
    kind === "primary" &&
      css`
        color: white;
        border-radius: 4px;
        font-weight: bold;
        background-color: ${PRIMARY_TEXT};
      `,
    kind === "secondary" &&
      css`
        color: white;
        border-radius: 4px;
        font-weight: bold;
        background-color: ${UNSTUCK_BLUE};
      `,
    kind === "outline" &&
      css`
        border-radius: 4px;
        font-weight: bold;
        border: 1px solid ${BORDER};
      `,
    className
  );

export default Button;
